// Initialize Swiper
var swiper = new Swiper(".partnerSwiper", {
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

// carousel banner Swiper
var swiper = new Swiper(".heroCarousel", {
  autoHeight: true,
  loop: true,
  navigation: {
    nextEl: ".swiper-control-next",
    prevEl: ".swiper-control-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

//bookmark icon toggle
function bookmark(btn) {
  btn.classList.toggle("del");
}

//increase font size
function increaseFontSize() {
  var txt = document.getElementById("setFontSize");
  var curSize = parseFloat(txt.style.fontSize);
  if (curSize <= 1.875 ) {
    txt.style.fontSize = (curSize + .125) + 'rem';
  } else {
    txt.style.fontSize = curSize;
  }
}

//decrease font size
function decreaseFontSize() {
  var txt = document.getElementById("setFontSize");
  var curSize = parseFloat(txt.style.fontSize);
  if (curSize >= 1 ) {
    txt.style.fontSize = (curSize - .125) + 'rem';
  } else {
    txt.style.fontSize = curSize;
  }
}

// edit account input
function accountEdit() {
  var account = document.getElementById('account');
  account.disabled = false;
  var accountBtnEdit = document.getElementById("account-btn-edit");
  var accountBtnCancel = document.getElementById("account-btn-cancel");
  accountBtnEdit.classList.add("hide");
  accountBtnCancel.classList.remove("hide");
}

// disabled account input
function accountCancel() {
  var account = document.getElementById('account');
  account.disabled = true;
  var accountBtnEdit = document.getElementById("account-btn-edit");
  var accountBtnCancel = document.getElementById("account-btn-cancel");
  accountBtnEdit.classList.remove("hide");
  accountBtnCancel.classList.add("hide");
}

//show pw change form
function changePW() {
  var pwBtn = document.getElementById("pwBtn");
  pwBtn.classList.toggle("hide");
  var pwForm = document.getElementById("pwForm");
  pwForm.classList.remove("hide");
}
//hide pw change form
function cancelPW() {
  var pwBtn = document.getElementById("pwBtn");
  pwBtn.classList.toggle("hide");
  var pwForm = document.getElementById("pwForm");
  pwForm.classList.add("hide");
}

//show info form
function changeInfo() {
  var infoHide = document.getElementById("info");
  infoHide.classList.toggle("hide");
  var infoForm = document.getElementById("infoForm");
  infoForm.classList.remove("hide");
}
//hide info change form
function cancelInfo() {
  var infoHide = document.getElementById("info");
  infoHide.classList.toggle("hide");
  var infoForm = document.getElementById("infoForm");
  infoForm.classList.add("hide");
}

//header fixed
document.addEventListener("DOMContentLoaded", function(){
  var header = document.getElementById("headerFixed");
  var headerTop = header.offsetTop;
  window.addEventListener('scroll', function() {
      if (window.scrollY > headerTop) {
        header.classList.add('fixed-top');
        // add padding top to show content behind navbar
        var headerHeight = header.offsetHeight;
        console.log(headerHeight);
        document.body.style.paddingTop = headerHeight + 'px';
      } else {
        header.classList.remove('fixed-top');
         // remove padding top from body
        document.body.style.paddingTop = '0';
      } 
  });
}); 
